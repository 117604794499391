import { FC } from 'react'
import { Container, Grid, makeStyles, Theme } from '@material-ui/core'

import GalleryItem from './GalleryItem'

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
    },
    root: {
        position: 'relative',
        flexGrow: 1,
    },
    gridContainer: {
        justifyContent: 'center',
    },
}));

type Props = {
    tokenCIDs: Array<string>
}

const Gallery: FC<Props> = (props: Props): JSX.Element => {

    const classes = useStyles()

    return (<Container className={classes.content} maxWidth="md">
        <div className={classes.root}>
            <Grid container spacing={1} className={classes.gridContainer}>
                {props.tokenCIDs.map((cid, index) => <GalleryItem cid={cid} index={index} key={index} />)}
            </Grid>
        </div>
    </Container>)
}

export default Gallery