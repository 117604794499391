import { FC } from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core'

import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
    link: {
        opacity: 1,
        color: '#ffffff',
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            opacity: 0.7,
            transition: 'opacity 0.3s ease-in',
            textDecoration: 'none'
        },
        '&:active': {
            textDecoration: 'none'
        },
        '&:link': {
            textDecoration: 'underline'
        },
        '&:visited': {
            textDecoration: 'none'
        }
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        margin: 'auto',
        maxWidth: 'inherit',
        backgroundColor: '#000000',
        color: '#ffffff',
        alignItems: 'center'
    }
}));

const AirdropFooter: FC = (): JSX.Element => {

    const classes = useStyles()

    return (<Container className={classes.footer}>
        <Typography>
            🎁 Airdrop 02: Sushi is live. Check your eligibility <Link to="/airdrops" className={classes.link}>here</Link>
        </Typography>
    </Container>)
}

export default AirdropFooter