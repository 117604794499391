import { FC, useState } from 'react'
import { Backdrop, Container, Fade, Link, makeStyles, Modal, Paper, Theme } from '@material-ui/core'

import SocialFooter from '../footer/SocialFooter'
import AirdropTemplate from './AirdropTemplate'

import AirdropImage from './sushi-airdrop.png'
import { AirdropCatagory } from './Airdrop'
import EligibilityChecker from '../eligibility-checker/EligibilityChecker'

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        padding: theme.spacing(1),
    },
    airdropComingSoon: {
        fontWeight: 'bold',
        fontFamily: 'Karla',
        fontSize: '24px',
    },
    eligibilityLink: {
        fontFamily: 'Karla',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#0076FF',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '500px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));

const AIRDROP_LIVE = parseInt(process.env.REACT_APP_AIRDROP_2_LIVE as string)

const CONTRACT_ADDR = process.env.REACT_APP_CLAIM_CONTRACT_ADDR_2 as string

const PROOF_1_URL = process.env.REACT_APP_SUSHI_PROOFS_1_URL as string
const PROOF_2_URL = process.env.REACT_APP_SUSHI_PROOFS_2_URL as string
const PROOF_3_URL = process.env.REACT_APP_SUSHI_PROOFS_3_URL as string
const PROOF_4_URL = process.env.REACT_APP_SUSHI_PROOFS_4_URL as string
const HASH_PROOF_1_URL = process.env.REACT_APP_SUSHI_HASH_PROOFS_1_URL as string

const ELIGIBILITY_CRITERIA_URL = process.env.REACT_APP_SUSHI_ELIGIBILITY_CRITERIA_URL as string

const Sushi: FC = (): JSX.Element => {

    const classes = useStyles()

    const noStateBtnText = (airdropDisabled: boolean, walletStatus: boolean, eligible: boolean, allClaimed: boolean): string => {
        if (airdropDisabled) {
            return "Coming soon"
        }
        if (allClaimed) {
            return 'All Reserved'
        }
        if (walletStatus && eligible) {
            return 'Select a fren type'
        }
        if (walletStatus && !eligible) {
            return "Ineligible"
        }
        return 'Connect wallet to check eligibility'
    }

    const initActionText = (uninitialisedTokenIDsLength: number, loading: boolean) => {
        if (loading) {
            return 'Bringing ' + uninitialisedTokenIDsLength + ' sushi home...'
        }
        if (uninitialisedTokenIDsLength === 1) {
            return 'Bring ' + uninitialisedTokenIDsLength + ' sushi home'
        }
        if (uninitialisedTokenIDsLength > 1) {
            return 'Bring ' + uninitialisedTokenIDsLength + ' sushis home'
        }
        return 'Bring sushi home'
    }

    const claimText = (loading: boolean, airdropDisabled: boolean, walletStatus: boolean, eligible: boolean, isClaimed: boolean, allClaimed: boolean): string => {
        if (airdropDisabled) {
            return "Coming soon"
        }
        if (loading) {
            return "Adding sushi to cart..."
        }
        if (allClaimed) {
            return "Exhausted"
        }
        if (isClaimed) {
            return "Added to cart"
        }
        if (walletStatus && eligible) {
            return "Add sushi to cart"
        }
        if (walletStatus && !eligible) {
            return "Ineligible"
        }
        return "Connect wallet to check eligibility"
    }

    const catagories: Array<AirdropCatagory> = [
        {
            name: "Early Farmers",
            proofUrl: PROOF_1_URL,
            hashProofUrl: HASH_PROOF_1_URL,
            merkleIdx: 0,
            info: 'All 1024 are available.'
        },
        {
            name: "Vested Sushi",
            proofUrl: PROOF_2_URL,
            hashProofUrl: HASH_PROOF_1_URL,
            merkleIdx: 1,
            info: 'All 1024 are available.'
        },
        {
            name: "Partner LPs",
            proofUrl: PROOF_3_URL,
            hashProofUrl: HASH_PROOF_1_URL,
            merkleIdx: 2,
            info: 'All 1024 are available.'
        },
        {
            name: "xSushi",
            proofUrl: PROOF_4_URL,
            hashProofUrl: HASH_PROOF_1_URL,
            merkleIdx: 3,
            info: 'All 1024 are available.'
        },
    ]

    const airdrop = <AirdropTemplate
        disabled={AIRDROP_LIVE ? false : true}
        name="Airdrop 02: Sushi"
        image={AirdropImage}
        description="Limited to 1024 in total. Each NFT is different. All of them are special. Some are extra special."
        catagories={catagories}
        hashProofUrl={HASH_PROOF_1_URL}
        noStateBtnText={noStateBtnText}
        initActionText={initActionText}
        claimText={claimText}
        catagoryText="What type of fren are you?"
        contractAddress={CONTRACT_ADDR}
    />

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }


    return (<>
        <Container className={classes.content}>
            {airdrop}
            <Link className={classes.eligibilityLink} href={ELIGIBILITY_CRITERIA_URL}>Eligibility Criteria</Link><br />
            <Link className={classes.eligibilityLink} onClick={handleOpen}>or, check an address</Link>
            <SocialFooter />
        </Container>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Paper className={classes.paper}>
                    <EligibilityChecker airdropName={"Airdrop 02: Sushi"} proofs={[PROOF_1_URL, PROOF_2_URL, PROOF_3_URL, PROOF_4_URL]} />
                </Paper>
            </Fade>
        </Modal>
    </>)
}

export default Sushi