import { FC, useEffect, useState } from 'react'
import { Container, makeStyles, Theme, CircularProgress } from '@material-ui/core'

import getFixedContract from '../contract/FixedContractProvider'
import Splash from '../splash/Splash'
import Gallery from '../gallery/Gallery'
import AirdropFooter from '../footer/AirdropFooter'
import SocialFooter from '../footer/SocialFooter'
import ComingSoonFooter from '../footer/ComingSoonFooter'

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    marginTop: theme.spacing(14),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(18),
    },
  },
  content: {
    minHeight: '50vh',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(14)
  },
}));

const AIRDROP_LIVE = parseInt(process.env.REACT_APP_AIRDROP_2_LIVE as string)

const Home: FC = (): JSX.Element => {

  const [tokenCIDs, setTokenCIDs] = useState([] as string[])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getNfts = async () => {
      const contract = getFixedContract()
      const supply = await contract.getTotalSupply()
      const tokenCIDs = await contract.getAllTokenURIsMultiCall(supply) as []
      setTokenCIDs(tokenCIDs)
    }
    getNfts().then(() => {
      setLoading(false)
    })
  }, [])

  const classes = useStyles();

  return (<>
    <Container className={classes.page}>
      <Splash />
      <Container className={classes.content}>
        {loading ? <CircularProgress /> : <Gallery tokenCIDs={tokenCIDs} />}
      </Container>
    </Container>
    <ComingSoonFooter />
    <SocialFooter />
    {AIRDROP_LIVE ? <AirdropFooter /> : <></>}
  </>)
}

export default Home