import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import Onboard from 'bnc-onboard';
import Web3 from 'web3';

export type WalletService = {
    address: string,
    network: number,
    balance: string,
    onboard: any,
    webthree: Web3,
    connect: () => void
    disconnect: () => void
    connectedStatus: boolean
}

export const WalletContext = createContext<WalletService>({
    address: "",
    network: 0,
    balance: "",
    onboard: {},
    webthree: {} as Web3,
    connect: () => { /* */ },
    disconnect: () => { /* */ },
    connectedStatus: false,
})

type Props = {
    children: ReactNode
}

const NETWORK_ID = parseInt(process.env.REACT_APP_WALLET_NETWORK_ID as string)

const WalletProvider: FC<Props> = ({ children }: Props): JSX.Element => {

    const [webthree, setWebThree] = useState({} as Web3)
    const [onboard, setOnboard] = useState({} as any)
    const [connectedStatus, setConnectedStatus] = useState(false)

    const [address, setAddress] = useState("")
    const [network, setNetwork] = useState(0)
    const [balance, setBalance] = useState("")

    useEffect(() => {
        const onboard = Onboard({
            dappId: '',
            networkId: NETWORK_ID,
            subscriptions: {
                address: setAddress,
                network: setNetwork,
                balance: setBalance,
                wallet: wallet => {
                    if (wallet.name === undefined) {
                        setWebThree({} as Web3)
                        setConnectedStatus(false)
                    } else {
                        const web3 = new Web3(wallet.provider)
                        setWebThree(web3)
                        setConnectedStatus(true)
                        console.log(`${wallet.name} is now connected!`)
                    }
                }
            },
        }) 
        setOnboard(onboard)
    }, [])

    const connect = async () => {
        await onboard.walletSelect()
        try {
            await onboard.walletCheck()
        } catch (err) {
            console.log(err.message)
        }

        console.log(onboard.getState().address)
    }

    const disconnect = async () => {
        await onboard.walletReset()
    }

    const state = {
        address: address,
        network: network,
        balance: balance,
        onboard: onboard,
        webthree: webthree,
        connect: connect,
        disconnect: disconnect,
        connectedStatus
    }

    return (<WalletContext.Provider value={state}>
        {children}
    </WalletContext.Provider>)
}

export default WalletProvider