import { FC, useState, useRef, useEffect, useContext } from 'react'
import {
  AppBar, Toolbar, makeStyles, createStyles, Theme, useTheme, useMediaQuery, Slide, useScrollTrigger, Button, Dialog, DialogContent, DialogActions, Container, Fade
} from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'

import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'

import WalletView from '../wallet/WalletView'
import NavLogo from './NavLogo'
import { WalletContext } from '../wallet/WalletProvider'
import FullPageMenu from '../menu/FullPageMenu'
import {ReactComponent as EvohLogo } from '../evoh-logo.svg'
import { CSSProperties } from '@material-ui/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    walletButton: {
    },
    menuButton: {
    },
    title: {
      flexGrow: 1,
    },
    toolbar: {
        justifyContent: 'flex-end',
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column-reverse'
        },
    },
    rightNav: {
      display: 'flex',
      alignItems: 'center',
      verticalAlign: 'middle',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2)
      },
    },
    dialog: {
      backgroundColor: '#000000',
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5)
    },
    walletContainer: {
      color: '#ffffff',
      padding: 0,
      width: 'auto',
      marginRight: '0',
      '& > . > #button': {
        color: '#ffffff'
      }
    },
    closeBtn: {
      color: '#ffffff',
      marginLeft: 0,
    },
    logo: {
      height: '50px',
      fill: '#ffffff',
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      },
    },
    menuHeader: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        justifyContent: 'space-between',
      }
    },
  }),
);

const NavBar: FC = (): JSX.Element => {

    const classes = useStyles()

    const rightNavRef = useRef<HTMLDivElement>(null)

    const [paddingLeft, setPaddingLeft] = useState(0)

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const wallet = useContext(WalletContext)

    useEffect(() => {
      if (isMobile) {
        setPaddingLeft(0)
      } else {
        const width = rightNavRef.current?.offsetWidth
        setPaddingLeft((width !== undefined) ? width : 0)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet.address])

    useEffect(() => {
      if (isMobile) {
        setPaddingLeft(0)
      } else {
        const width = rightNavRef.current?.offsetWidth
        setPaddingLeft((width !== undefined) ? width : 0)
      }
    }, [isMobile])

    const trigger = useScrollTrigger();

    const [openMenu, setOpenMenu] = useState(false)

    const handleOpenMenu = () => {
      setOpenMenu(true)
    }

    const handleCloseMenu = () => {
      setOpenMenu(false);
    }

    const walletBtnConnectedNav: CSSProperties = {
      backgroundColor: '#ffffff',
      borderColor: '#000000',
      color: '#000000',
    }
    const walletBtnDisconnectedNav: CSSProperties = {
      backgroundColor: '#000000',
      borderColor: '#000000',
      color: '#ffffff',
    }
    const walletBtnMenu: CSSProperties = {
      backgroundColor: '#ffffff',
      borderColor: '#000000',
      color: '#000000',
    }

    return (<>
      <div className={classes.root}>
        <CssBaseline />
        <Slide appear={false} direction="down" in={!trigger}>
          <AppBar
            color="inherit"
            position="fixed"
            style={{boxShadow: '-2px 0px 8px rgba(0, 0, 0, 0.1)'}}
          >
            <Toolbar className={classes.toolbar}>
              <NavLogo padLeft={paddingLeft} />
              <div className={classes.rightNav} ref={rightNavRef}>
                <div className={classes.walletButton}>
                    <WalletView buttonStyle={wallet.connectedStatus ? walletBtnConnectedNav : walletBtnDisconnectedNav} />
                </div>
                <div className={classes.menuButton}>
                  <Button onClick={handleOpenMenu}><MenuIcon /></Button>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </Slide>
        <Dialog classes={{paper: classes.dialog}} fullScreen open={openMenu} onClose={handleCloseMenu} TransitionComponent={Fade} transitionDuration={500}>
          <Container className={classes.menuHeader}>
            <EvohLogo className={classes.logo} />
            <DialogActions>
              <Container className={classes.walletContainer}>
                <WalletView buttonStyle={walletBtnMenu} />
              </Container>
              <Button onClick={handleCloseMenu} className={classes.closeBtn}>
                <CloseIcon />
              </Button>
            </DialogActions>
          </Container>
          <DialogContent>
            <FullPageMenu action={handleCloseMenu} />
          </DialogContent>
        </Dialog>
      </div>
    </>)
}

export default NavBar