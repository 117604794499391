import { FC } from 'react'
import { makeStyles, Typography } from '@material-ui/core'

import Image from './snowwhite-bg.png'

const useStyles = makeStyles((theme) => ({
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        flexDirection: 'column'
    },
    image: {
        opacity: 0.6,
        width: '100%'
    },
    text: {
        fontFamily: 'Nunito Sans',
        fontSize: '16px',
        textTransform: 'uppercase'
    }
}));

const ComingSoonFooter: FC = (): JSX.Element => {

    const classes = useStyles()

    return (<div className={classes.footer}>
        <Typography className={classes.text}>Coming soon</Typography>
        <img src={Image} className={classes.image} alt="snow white" />
    </div>)
}

export default ComingSoonFooter 