
import { FC, ReactNode, useContext, useEffect, useState } from 'react'
import getClaimableContract from '../contract/ClaimableContractProvider';
import { WalletContext } from '../wallet/WalletProvider';
import { AirdropCatagory, ProofObj } from './Airdrop';
import { Button, Container, makeStyles, Typography, Theme } from '@material-ui/core'

import { GasContext } from '../gas/GasProvider';
import Web3 from 'web3';

type Props = {
    disabled: boolean
    airdrop: AirdropCatagory
    claimCount: number
    eligible: boolean
    proof: ProofObj
    handleClaimed: () => void
    submitHashesBtn: ReactNode
    claimText: (loading: boolean, airdropDisabled: boolean, walletStatus: boolean, eligible: boolean, isClaimed: boolean, allClaimed: boolean) => string
    contractAddress: string
}

const useStyles = makeStyles((theme: Theme) => ({
    linkcontainer: {
        padding: 0,
        marginBottom: theme.spacing(2)
    },
    button: {
        minWidth: '300px',
        width: 'fit-content',
        padding: '16px',
        textTransform: 'none',
        backgroundColor: '#000000',
        borderColor: '#000000',
        color: '#ffffff',
        boxShadow: 'none',
        borderRadius: '0',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#424242'
        },
        '&.Mui-disabled': {
            color: '#606060'
        },
        [theme.breakpoints.down('xs')]: {
            width: 'inherit',
            minWidth: 'auto',
            alignItems: 'center',
            justifyContent: 'center'
        },
    },
    btnText: {
        fontFamily: 'Karla',
        fontSize: '13px',
    },
    claimOptionInfo: {
        minHeight: '42px',
        fontFamily: 'Karla',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0
        },
    },
    airdropActions: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
    }
}));

const claimDisabled = (airdropDisabled: boolean, walletStatus: boolean, eligible: boolean, isClaimed: boolean, allClaimed: boolean): boolean => {
    if (airdropDisabled || allClaimed || !walletStatus || !eligible || isClaimed) {
        return true
    }

    return false
}

const ClaimOption: FC<Props> = (props: Props): JSX.Element => {
    const {
        name,
        merkleIdx,
        info
    } = props.airdrop

    const classes = useStyles();

    const wallet = useContext(WalletContext)

    const gas = useContext(GasContext)

    const [loading, setLoading] = useState(false)

    const [claimSupply, setClaimSupply] = useState(0)
    const [maxClaimSupply, setMaxClaimSupply] = useState(0)

    useEffect(() => {
        if (!props.disabled) {
            const getClaimData = async () => {
                const contract = getClaimableContract(props.contractAddress)
                const claimData = await contract.getClaimData(merkleIdx)
                setClaimSupply(claimData.count)
                setMaxClaimSupply(claimData.limit)
            }
            getClaimData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.disabled, merkleIdx, wallet, wallet.connectedStatus])

    const [isClaimed, setIsClaimed] = useState(false)
    useEffect(() => {
        if (wallet.connectedStatus === false ||
            wallet.address === "" ||
            wallet.address === undefined
        ) {
            setIsClaimed(false)
            return
        }

        if (!props.disabled) {
            const getIsClaimed = async () => {
                const contract = getClaimableContract(props.contractAddress)
                const claimed = await contract.isClaimed(wallet, merkleIdx)
                setIsClaimed(claimed)
            }
            getIsClaimed()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merkleIdx, wallet, wallet.connectedStatus, wallet.address, props.disabled])

    const handleClaim = () => {
        if (props.proof === undefined) {
            return
        }

        const userProof = props.proof[Web3.utils.toChecksumAddress(wallet.address)]
        if (userProof === undefined) {
            console.error('user is not eligible')
            return
        }

        if (!props.disabled) {
            const doClaim = async () => {
                setLoading(true)
                const contract = getClaimableContract(props.contractAddress)
                const res = await contract.claim(gas.price, wallet, merkleIdx, userProof)
                console.log(res)
            }
            doClaim().then(() => {
                setLoading(false)
                props.handleClaimed()
            }).catch(() => {
                setLoading(false)
                props.handleClaimed()
            })
        }
    }

    const infoString = maxClaimSupply + " allocated to " + name + " holders. " + claimSupply + " claimed so far." 

    const claimActionText = props.claimText(
        loading,
        props.disabled,
        wallet.connectedStatus,
        props.eligible,
        isClaimed,
        claimSupply === maxClaimSupply
    )
    const claimActionDisabled = claimDisabled(
        props.disabled,
        wallet.connectedStatus,
        props.eligible,
        isClaimed,
        claimSupply === maxClaimSupply
    )

    return (<Container className={classes.linkcontainer}>
        <Typography className={classes.claimOptionInfo}>
            {info.length > 0 ? info : infoString} Claiming requires 2 transactions.
        </Typography>
        <Container className={classes.airdropActions}>
            <Button
                style={{ marginBottom: '12px' }}
                className={classes.button}
                disabled={claimActionDisabled || loading}
                variant="contained"
                onClick={handleClaim}>
                <Typography className={classes.btnText}>{claimActionText}</Typography>
            </Button>
            {props.submitHashesBtn}
        </Container>
    </Container>)
}

export default ClaimOption