import { FC, useEffect, useState } from 'react'
import { Typography, Grid, Link, makeStyles } from '@material-ui/core'
import { Nft } from '../nft'
import axios from 'axios';

const IPFS_URL = process.env.REACT_APP_IPFS_URL
const OPENSEA_BASE_URL = process.env.REACT_APP_OPENSEA_BASE_URL
const FIXED_CONTRACT_ADDR = process.env.REACT_APP_FIXED_CONTRACT_ADDR

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Karla',
        fontWeight: 'bold',
        color: '#000000'
    },
    price: {
        color: '#000000'
    },
    accordian: {
        position: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0',
        left: 0,
    },
    itemPanel: {
        margin: theme.spacing(1.5),
        maxWidth: 'fit-content',
        opacity: 1,
        color: '#000000',
        cursor: 'pointer',
        textDecoration: 'none',
        '& > a:hover': {
            opacity: 0.7,
            transition: 'opacity 0.3s ease-in',
            textDecoration: 'none'
        },
    },
    link: {
        display: 'block'
    },
    image: {
        height: '400px',
        boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1)'
    }
}));

type Props = {
    cid: string
    index: number
}

const GalleryItem: FC<Props> = (props: Props): JSX.Element => {

    const classes = useStyles()

    const [nft, setNft] = useState({} as Nft)

    useEffect(() => {
        axios.get(`${IPFS_URL}${props.cid}`).then((res) => {
            setNft(res.data)
        })
    }, [props.cid])

    const openseaUrl = `${OPENSEA_BASE_URL}assets/${FIXED_CONTRACT_ADDR}/${props.index}`

    return (<Grid item xs={4} className={classes.itemPanel}>
        {nft.image !== undefined ? <Link className={classes.link} href={openseaUrl} target="_blank" rel="noreferrer">
            <img loading="lazy" className={classes.image} src={IPFS_URL + nft.image} alt={nft.name} />
            <Typography variant="h6" className={classes.title}>{nft.name}</Typography>
        </Link> : <></>}
    </Grid>)
}

export default GalleryItem