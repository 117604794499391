import { WalletService } from '../wallet/WalletProvider'

import { AbiItem } from 'web3-utils'

import { EvohClaimable } from '../abi/claimable';
import Web3 from 'web3';

import {
    Multicall,
    ContractCallResults,
    ContractCallContext,
  } from 'ethereum-multicall';

type ClaimableContract = {
    getUnitialisedTokenIDs: (wallet: WalletService) => Promise<number[] | undefined>
    getUnitialisedTokenIDsMutliCall: (wallet: WalletService) => Promise<number[] | undefined>
    getMaxTotalSupply: () => Promise<number>
    getClaimCount: () => Promise<number>
    getSupplyDataMultiCall: () => Promise<{claimCount: number, maxTotalSupply: number}>
    getClaimData: (idx: number) => Promise<{count: number, limit: number}>
    isClaimed: (wallet: WalletService, idx: number) => Promise<boolean>
    claim: (gasPrice: number, wallet: WalletService, idx: number, proof: Array<string>) => Promise<any>
    submitHashes: (gasPrice: number, wallet: WalletService, indexes: Array<number>, hashes: Array<string>, proofs: Array<Array<string>>) => Promise<any>
}

const INFURA_URL = process.env.REACT_APP_INFURA_URL as string

const getClaimableContract = (contractAddress: string): ClaimableContract => {

    const getUnitialisedTokenIDsMultiCall = async (wallet: WalletService) => {
        if (wallet.connectedStatus === false) {
            return
        }

        if (wallet.address === undefined) {
            return
        }

        console.log('calling getUnitialisedTokenIDsMultiCall')
        const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL))
        let evohContract = new web3.eth.Contract(EvohClaimable as AbiItem[], contractAddress)

        const balance = await evohContract.methods.balanceOf(wallet.address).call()

        if (balance < 1) {
            return []
        }

        const multicall = new Multicall({
            web3Instance: web3,
            tryAggregate: true
        })

        let tokenOfOwnerByIndexCalls = []
        for (let i = 0; i < balance; i++) {
            tokenOfOwnerByIndexCalls.push(
                {
                    reference: 'tokenOfOwnerByIndexCall',
                    methodName: 'tokenOfOwnerByIndex',
                    methodParameters: [wallet.address, i],
                }
            )
        }

        const tokenOfOwnerByIndexCallContext: ContractCallContext[] = [
            {
                reference: 'EvohClaimable',
                contractAddress: contractAddress,
                abi: EvohClaimable,
                calls: tokenOfOwnerByIndexCalls
            },
        ]

        const tokenOfOwnerByIndexCallContextResults: ContractCallResults = await multicall.call(tokenOfOwnerByIndexCallContext)

        let tokenURICalls = []
        for (let i = 0; i < balance; i++) {
            const tokenOfOwnerByIndexCallValues = tokenOfOwnerByIndexCallContextResults.results.EvohClaimable.callsReturnContext[i].returnValues
            tokenURICalls.push(
                {
                    reference: 'tokenURICall',
                    methodName: 'tokenURI',
                    methodParameters: [Number(tokenOfOwnerByIndexCallValues[0].hex)],
                }
            )
        }

        const tokenURICallContext: ContractCallContext[] = [
            {
                reference: 'EvohClaimable',
                contractAddress: contractAddress,
                abi: EvohClaimable,
                calls: tokenURICalls 
            },
        ]

        const tokenURICallContextResults: ContractCallResults = await multicall.call(tokenURICallContext)

        let tokenIDs: Array<number> = []
        for (let i = 0; i < balance; i++) {
            const tokenURICallValues = tokenURICallContextResults.results.EvohClaimable.callsReturnContext[i].returnValues
            if (tokenURICallValues[0].length === 0) {
                const returnValue = tokenOfOwnerByIndexCallContextResults.results.EvohClaimable.callsReturnContext[i].returnValues
                tokenIDs.push(Number(returnValue[0].hex))
            }
        }

        return tokenIDs
    }

    const getUnitialisedTokenIDs = async (wallet: WalletService) => {
        if (wallet.connectedStatus === false) {
            return
        }

        if (wallet.address === undefined) {
            return
        }

        const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL))
        let evohContract = new web3.eth.Contract(EvohClaimable as AbiItem[], contractAddress)

        let tokenIDs: Array<number> = []
        const balance = await evohContract.methods.balanceOf(wallet.address).call()
        for (let i = 0; i < balance; i++) {
            const tid = await evohContract.methods.tokenOfOwnerByIndex(wallet.address, i).call()
            const tokenURI = await evohContract.methods.tokenURI(parseInt(tid)).call()
            if (tokenURI.length === 0) {
                tokenIDs.push(parseInt(tid))
            }
        }
        return tokenIDs
    }

    const getSupplyDataMultiCall = async () => {
        console.log('calling getSupplyDataMultiCall')
        const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL))

        const multicall = new Multicall({ web3Instance: web3, tryAggregate: true })

        const supplyDataCalls = [
            {
                reference: 'maxTotalSupplyCall',
                methodName: 'maxTotalSupply',
                methodParameters: [],
            },
            {
                reference: 'totalSupplyCall',
                methodName: 'totalSupply',
                methodParameters: [],
            }
        ]

        const supplyDataCallContext: ContractCallContext[] = [
            {
                reference: 'EvohClaimable',
                contractAddress: contractAddress,
                abi: EvohClaimable,
                calls: supplyDataCalls
            },
        ]

        const supplyDataCallContextResults: ContractCallResults = await multicall.call(supplyDataCallContext)

        return {
            maxTotalSupply: Number(supplyDataCallContextResults.results.EvohClaimable.callsReturnContext[0].returnValues[0].hex),
            claimCount: Number(supplyDataCallContextResults.results.EvohClaimable.callsReturnContext[1].returnValues[0].hex)
        }
    }

    const getMaxTotalSupply = async () => {
        console.log('calling getMaxTotalSupply')
        const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL))
        let evohContract = new web3.eth.Contract(EvohClaimable as AbiItem[], contractAddress)
        const maxTotalSupply = await evohContract.methods.maxTotalSupply().call()
        return parseInt(maxTotalSupply)
    }

    const getClaimCount = async () => {
        console.log('calling getClaimCount')
        const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL))
        let evohContract = new web3.eth.Contract(EvohClaimable as AbiItem[], contractAddress)
        const claimCount = await evohContract.methods.totalSupply().call()
        return parseInt(claimCount)
    }

    const getClaimData = async (merkleIdx: number) => {
        console.log('calling getClaimData')
        const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL))
        let evohContract = new web3.eth.Contract(EvohClaimable as AbiItem[], contractAddress)
        return await evohContract.methods.claimData(merkleIdx).call()
     }

    const isClaimed = async (wallet: WalletService, merkleIdx: number) => {
        console.log('calling isClaimed')
        if (wallet.connectedStatus === false) {
            return
        }

        if (wallet.address === undefined) {
            return
        }

        const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL))
        let evohContract = new web3.eth.Contract(EvohClaimable as AbiItem[], contractAddress)
        return await evohContract.methods.isClaimed(merkleIdx, wallet.address).call()
    }

    const claim = async (
        gasPrice: number,
        wallet: WalletService,
        merkleIdx: number,
        proof: Array<string>,
    ) => {
        console.log('calling claim')
        if (wallet.connectedStatus === false) {
            return
        }

        if (wallet.address === undefined) {
            return
        }

        let evohContract = new wallet.webthree.eth.Contract(EvohClaimable as AbiItem[], contractAddress)
        return await evohContract.methods.claim(merkleIdx, proof).send({from: wallet.address, gasPrice: gasPrice})
    }

    const submitHashes = async (
        gasPrice: number,
        wallet: WalletService,
        indexes: Array<number>,
        hashes: Array<string>,
        proofs: Array<Array<string>>
    ) => {
        if (wallet.connectedStatus === false) {
            return
        }

        if (wallet.address === undefined) {
            return
        }

        let evohContract = new wallet.webthree.eth.Contract(EvohClaimable as AbiItem[], contractAddress)
        return await evohContract.methods.submitHashes(indexes, hashes, proofs).send({from: wallet.address, gasPrice: gasPrice})
    }

    return {
        getUnitialisedTokenIDsMutliCall: getUnitialisedTokenIDsMultiCall,
        getUnitialisedTokenIDs: getUnitialisedTokenIDs,
        getMaxTotalSupply: getMaxTotalSupply,
        getClaimCount: getClaimCount,
        getSupplyDataMultiCall: getSupplyDataMultiCall,
        getClaimData: getClaimData,
        isClaimed: isClaimed,
        claim: claim,
        submitHashes: submitHashes,
    }
}

export default getClaimableContract