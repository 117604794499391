import { FC } from 'react'
import { Container, makeStyles, Theme} from '@material-ui/core'

import Claim from './Claim'
import { AirdropCatagory } from './Airdrop';

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
    },
}));


type Props = {
    disabled: boolean
    image: string
    name: string
    description: string
    catagories: Array<AirdropCatagory>
    hashProofUrl: string
    noStateBtnText: (airdropDisabled: boolean, walletStatus: boolean, eligible: boolean, allClaimed: boolean) => string
    initActionText: (uninitialisedTokenIDsLength: number, loading: boolean) => string
    claimText: (loading: boolean, airdropDisabled: boolean, walletStatus: boolean, eligible: boolean, isClaimed: boolean, allClaimed: boolean) => string
    catagoryText: string
    contractAddress: string
}

const AirdropTemplate: FC<Props> = (props: Props): JSX.Element => {

    const classes = useStyles()

    return (<Container className={classes.content}>
        <Claim
            disabled={props.disabled}
            image={props.image}
            name={props.name}
            description={props.description}
            catagories={props.catagories}
            hashProofUrl={props.hashProofUrl}
            noStateBtnText={props.noStateBtnText}
            initActionText={props.initActionText}
            claimText={props.claimText}
            catagoryText={props.catagoryText}
            contractAddress={props.contractAddress}
        />
    </Container>)
}

export default AirdropTemplate