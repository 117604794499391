import { FC } from 'react'
import { makeStyles, Link, Typography } from '@material-ui/core'

import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import GitHubIcon from '@material-ui/icons/GitHub';

import { ReactComponent as IconDiscord } from './icon-discord.svg'
import { ReactComponent as IconTelegram } from './icon-telegram.svg'

const useStyles = makeStyles((theme) => ({
    link: {
        opacity: 1,
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            opacity: 0.7,
            transition: 'opacity 0.3s ease-in',
            textDecoration: 'none'
        },
        '&:active': {
            textDecoration: 'none'
        },
        '&:link': {
            textDecoration: 'underline'
        },
        '&:visited': {
            textDecoration: 'none'
        }
    },
    socialIcon: {
        verticalAlign: 'middle',
    },
    text: {
        fontFamily: 'Nunito Sans',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    socialLink: {
        marginRight: theme.spacing(2)
    },
    linkContainer: {
        display: 'flex',
    }
}));

type Props = {
    color: string
}

const SocialLinks: FC<Props> = (props: Props): JSX.Element => {

    const classes = useStyles()

    return (<div style={{color: props.color}} className={classes.linkContainer}>
        <Link color="inherit" className={classes.socialLink} href="http://t.me/EvohOrigins"><IconTelegram className={classes.socialIcon} /></Link>
        <Link color="inherit" className={classes.socialLink} href="http://discord.gg/q4zyjQ6WAp"><IconDiscord className={classes.socialIcon} /></Link>
        <Link color="inherit" className={classes.socialLink} href="https://twitter.com/anonevoh"><TwitterIcon className={classes.socialIcon} /></Link>
        <Link color="inherit" className={classes.socialLink} href="https://www.instagram.com/evoh.ink/"><InstagramIcon className={classes.socialIcon} /></Link>
        <Link color="inherit" className={classes.socialLink} href="https://github.com/evoh-nft"><GitHubIcon className={classes.socialIcon} /></Link>
        <Typography className={classes.text}>MMXXI</Typography>
    </div>)
}

export default SocialLinks