export const EvohFixed = [
    {
        'inputs': [
            {
                'internalType': "string",
                'name': "_name",
                'type': "string"
            },
            {
                'internalType': "string",
                'name': "_symbol",
                'type': "string"
            },
            {
                'internalType': "string[]",
                'name': "_tokenURIs",
                'type': "string[]"
            },
            {
                'internalType': "address[]",
                'name': "_owners",
                'type': "address[]"
            }
        ],
        'name': "constructor",
        'stateMutability': "nonpayable",
        'type': "constructor"
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': "address",
                'name': "_owner",
                'type': "address"
            },
            {
                'indexed': true,
                'internalType': "address",
                'name': "_approved",
                'type': "address"
            },
            {
                'indexed': true,
                'internalType': "uint256",
                'name': "_tokenId",
                'type': "uint256"
            }
        ],
        'name': "Approval",
        'type': "event"
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': "address",
                'name': "_owner",
                'type': "address"
            },
            {
                'indexed': true,
                'internalType': "address",
                'name': "_operator",
                'type': "address"
            },
            {
                'indexed': false,
                'internalType': "bool",
                'name': "_approved",
                'type': "bool"
            }
        ],
        'name': "ApprovalForAll",
        'type': "event"
    },
    {
        'anonymous': false,
        'inputs': [
            {
                'indexed': true,
                'internalType': "address",
                'name': "_from",
                'type': "address"
            },
            {
                'indexed': true,
                'internalType': "address",
                'name': "_to",
                'type': "address"
            },
            {
                'indexed': true,
                'internalType': "uint256",
                'name': "_tokenId",
                'type': "uint256"
            }
        ],
        'name': "Transfer",
        'type': "event"
    },
    {
        'inputs': [
            {
                'internalType': "address",
                'name': "approved",
                'type': "address"
            },
            {
                'internalType': "uint256",
                'name': "tokenId",
                'type': "uint256"
            }
        ],
        'name': "approve",
        'outputs': [],
        'stateMutability': "nonpayable",
        'type': "function"
    },
    {
        'inputs': [
            {
                'internalType': "address",
                'name': "_owner",
                'type': "address"
            }
        ],
        'name': "balanceOf",
        'outputs': [
            {
                'internalType': "uint256",
                'name': "",
                'type': "uint256"
            }
        ],
        'stateMutability': "view",
        'type': "function"
    },
    {
        'inputs': [
            {
                'internalType': "uint256",
                'name': "tokenId",
                'type': "uint256"
            }
        ],
        'name': "getApproved",
        'outputs': [
            {
                'internalType': "address",
                'name': "",
                'type': "address"
            }
        ],
        'stateMutability': "view",
        'type': "function"
    },
    {
        'inputs': [
            {
                'internalType': "address",
                'name': "owner",
                'type': "address"
            },
            {
                'internalType': "address",
                'name': "operator",
                'type': "address"
            }
        ],
        'name': "isApprovedForAll",
        'outputs': [
            {
                'internalType': "bool",
                'name': "",
                'type': "bool"
            }
        ],
        'stateMutability': "view",
        'type': "function"
    },
    {
        'inputs': [],
        'name': "name",
        'outputs': [
            {
                'internalType': "string",
                'name': "",
                'type': "string"
            }
        ],
        'stateMutability': "view",
        'type': "function"
    },
    {
        'inputs': [
            {
                'internalType': "uint256",
                'name': "tokenId",
                'type': "uint256"
            }
        ],
        'name': "ownerOf",
        'outputs': [
            {
                'internalType': "address",
                'name': "",
                'type': "address"
            }
        ],
        'stateMutability': "view",
        'type': "function"
    },
    {
        'inputs': [
            {
                'internalType': "address",
                'name': "_from",
                'type': "address"
            },
            {
                'internalType': "address",
                'name': "_to",
                'type': "address"
            },
            {
                'internalType': "uint256",
                'name': "_tokenId",
                'type': "uint256"
            }
        ],
        'name': "safeTransferFrom",
        'outputs': [],
        'stateMutability': "nonpayable",
        'type': "function"
    },
    {
        'inputs': [
            {
                'internalType': "address",
                'name': "_from",
                'type': "address"
            },
            {
                'internalType': "address",
                'name': "_to",
                'type': "address"
            },
            {
                'internalType': "uint256",
                'name': "_tokenId",
                'type': "uint256"
            },
            {
                'internalType': "bytes",
                'name': "_data",
                'type': "bytes"
            }
        ],
        'name': "safeTransferFrom",
        'outputs': [],
        'stateMutability': "nonpayable",
        'type': "function"
    },
    {
        'inputs': [
            {
                'internalType': "address",
                'name': "operator",
                'type': "address"
            },
            {
                'internalType': "bool",
                'name': "approved",
                'type': "bool"
            }
        ],
        'name': "setApprovalForAll",
        'outputs': [],
        'stateMutability': "nonpayable",
        'type': "function"
    },
    {
        'inputs': [
            {
                'internalType': "bytes4",
                'name': "",
                'type': "bytes4"
            }
        ],
        'name': "supportsInterface",
        'outputs': [
            {
                'internalType': "bool",
                'name': "",
                'type': "bool"
            }
        ],
        'stateMutability': "view",
        'type': "function"
    },
    {
        'inputs': [],
        'name': "symbol",
        'outputs': [
            {
                'internalType': "string",
                'name': "",
                'type': "string"
            }
        ],
        'stateMutability': "view",
        'type': "function"
    },
    {
        'inputs': [
            {
                'internalType': "uint256",
                'name': "_index",
                'type': "uint256"
            }
        ],
        'name': "tokenByIndex",
        'outputs': [
            {
                'internalType': "uint256",
                'name': "",
                'type': "uint256"
            }
        ],
        'stateMutability': "view",
        'type': "function"
    },
    {
        'inputs': [
            {
                'internalType': "address",
                'name': "_owner",
                'type': "address"
            },
            {
                'internalType': "uint256",
                'name': "_index",
                'type': "uint256"
            }
        ],
        'name': "tokenOfOwnerByIndex",
        'outputs': [
            {
                'internalType': "uint256",
                'name': "",
                'type': "uint256"
            }
        ],
        'stateMutability': "view",
        'type': "function"
    },
    {
        'inputs': [
            {
                'internalType': "uint256",
                'name': "tokenId",
                'type': "uint256"
            }
        ],
        'name': "tokenURI",
        'outputs': [
            {
                'internalType': "string",
                'name': "",
                'type': "string"
            }
        ],
        'stateMutability': "view",
        'type': "function"
    },
    {
        'inputs': [],
        'name': "totalSupply",
        'outputs': [
            {
                'internalType': "uint256",
                'name': "",
                'type': "uint256"
            }
        ],
        'stateMutability': "view",
        'type': "function"
    },
    {
        'inputs': [
            {
                'internalType': "address",
                'name': "_from",
                'type': "address"
            },
            {
                'internalType': "address",
                'name': "_to",
                'type': "address"
            },
            {
                'internalType': "uint256",
                'name': "_tokenId",
                'type': "uint256"
            }
        ],
        'name': "transferFrom",
        'outputs': [],
        'stateMutability': "nonpayable",
        'type': "function"
    }
];