import axios from 'axios';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';

export type GasService = {
    price: number
}

export const GasContext = createContext<GasService>({
    price: 0
})

type Props = {
    children: ReactNode
}

const GAS_API_URL = process.env.REACT_APP_GAS_API_URL as string

const GasProvider: FC<Props> = ({ children }: Props): JSX.Element => {

    const timeInterval = 15000

    const [price, setPrice] = useState(0)

    useEffect(() => {
        axios.get(GAS_API_URL).then(result => {
            setPrice(result.data.data.rapid)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const getGasPrice = () => {
            axios.get(GAS_API_URL).then(result => {
                setPrice(result.data.data.rapid)
            })
        }
        const interval = setInterval(getGasPrice, timeInterval)
        return () => clearInterval(interval)
    }, [price])

    const state = {
        price: price
    }

    return (<GasContext.Provider value={state}>
        {children}
    </GasContext.Provider>)
}

export default GasProvider 