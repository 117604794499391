import { AbiItem } from 'web3-utils'
import { EvohFixed } from '../abi/fixed';
import Web3 from 'web3';

import {
    Multicall,
    ContractCallResults,
    ContractCallContext,
  } from 'ethereum-multicall';

type FixedContract = {
    getAllTokenURIsMultiCall: (supply: number) => Promise<string[] | undefined>
    getAllTokenURIs: (supply: number) => Promise<string[] | undefined>
    getTotalSupply: () => Promise<number>
}

const CONTRACT_ADDR = process.env.REACT_APP_FIXED_CONTRACT_ADDR as string

const INFURA_URL = process.env.REACT_APP_INFURA_URL as string

const getFixedContract = (): FixedContract => {

    const getTotalSupply = async () => {
        const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL))
        let evohContract = new web3.eth.Contract(EvohFixed as AbiItem[], CONTRACT_ADDR)

        const supply: number = await evohContract.methods.totalSupply().call()
        return supply
    }

    const getAllTokenURIs = async (supply: number) => {
        const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL))
        let evohContract = new web3.eth.Contract(EvohFixed as AbiItem[], CONTRACT_ADDR)

        let tokenURIs: string[] = []

        for (let i = 0; i < supply ; i++) {
            const res = await evohContract.methods.tokenURI(i).call()
            tokenURIs.push(res)
        }

        return tokenURIs
    }

    const getAllTokenURIsMultiCall = async (supply: number) => {
        const web3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL))

        const multicall = new Multicall({ web3Instance: web3, tryAggregate: true })

        let tokenURICalls = []
        for (let i = 0; i < supply ; i++) {
            tokenURICalls.push(
                {
                    reference: 'tokenURICall',
                    methodName: 'tokenURI',
                    methodParameters: [i],
                }
            )
        }

        const tokenURICallContext: ContractCallContext[] = [
            {
                reference: 'EvohFixed',
                contractAddress: CONTRACT_ADDR,
                abi: EvohFixed,
                calls: tokenURICalls 
            },
        ]

        const tokenURICallContextResults: ContractCallResults = await multicall.call(tokenURICallContext)

        let tokenURIs: Array<string> = []
        tokenURICallContextResults.results.EvohFixed.callsReturnContext.forEach((a) => {
            tokenURIs.push(a.returnValues[0])
        })

        return tokenURIs
    }

    return {
        getAllTokenURIsMultiCall: getAllTokenURIsMultiCall,
        getAllTokenURIs: getAllTokenURIs,
        getTotalSupply: getTotalSupply
    }
}

export default getFixedContract