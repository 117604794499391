import { FC, useContext, useEffect, useState } from 'react'
import getClaimableContract from '../contract/ClaimableContractProvider';
import { WalletContext, WalletService } from '../wallet/WalletProvider';
import axios from 'axios';
import { AirdropCatagory, HashProofObj, ProofDict, ProofObj } from './Airdrop';
import { Button, Container, makeStyles, Typography, Theme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { GasContext } from '../gas/GasProvider';
import ClaimOption from './ClaimOption';
import Web3 from 'web3';

type Props = {
    disabled: boolean
    image: string
    name: string
    description: string
    catagories: Array<AirdropCatagory>
    hashProofUrl: string
    noStateBtnText: (airdropDisabled: boolean, walletStatus: boolean, eligible: boolean, allClaimed: boolean) => string
    initActionText: (uninitialisedTokenIDsLength: number, loading: boolean) => string
    claimText: (loading: boolean, airdropDisabled: boolean, walletStatus: boolean, eligible: boolean, isClaimed: boolean, allClaimed: boolean) => string
    catagoryText: string
    contractAddress: string
}

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        },
    },
    text: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        textAlign: 'left',
        fontFamily: 'Karla',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(2),
        },
    },
    imageContainer: {
        marginRight: theme.spacing(6),
        padding: 0,
        flexGrow: 1,
    },
    image: {
        height: '550px',
        padding: 0,
        boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.down('xs')]: {
            height: 'unset',
            width: 'inherit',
            minWidth: 'auto'
        }, 
    },
    description: {
        opacity: 0.6,
        marginBottom: theme.spacing(2),
        fontFamily: 'Karla'
    },
    title: {
        marginBottom: theme.spacing(2),
        fontFamily: 'Karla',
        fontWeight: 'bold',
        fontSize: '32px'
    },
    numberClaimed: {
        fontFamily: 'Karla'
    },
    linkcontainer: {
        padding: 0,
        marginBottom: theme.spacing(2)
    },
    info: {
        padding: 0,
    },
    airdrop: {
        padding: 0,
    },
    button: {
        minWidth: '300px',
        width: 'fit-content',
        padding: '16px',
        textTransform: 'none',
        backgroundColor: '#000000',
        borderColor: '#000000',
        color: '#ffffff',
        boxShadow: 'none',
        borderRadius: '0',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#424242'
        },
        '&.Mui-disabled': {
            color: '#606060'
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: 'auto',
            width: 'inherit',
            alignItems: 'center',
            justifyContent: 'center'
        },
    },
    btnText: {
        fontFamily: 'Karla',
        fontSize: '13px',
    },
    airdropCatagories: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            margin: 0
        },
    },
    catagoryBtn: {
        minWidth: '120px',
        width: 'fit-content',
        marginRight: theme.spacing(1),
        background: '#FFFFFF',
        border: '1px solid #000000',
        boxSizing: 'border-box',
        borderRadius: '24px',
        textTransform: 'none',
        fontFamily: 'Karla',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        "&.Mui-disabled": {
            border: '1px solid rgba(0,0,0,0.2)',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginRight: 0,
            marginBottom: theme.spacing(2)
        },
    },
    catagoryTitle: {
        marginBottom: theme.spacing(2),
        fontFamily: 'Karla',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1),
        },
    },
    claimOptionInfo: {
        minHeight: '42px',
        fontFamily: 'Karla',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('xs')]: {
            marginBottom: 0
        },
    },
    airdropActions: {
        padding: 0,
        display: 'flex',
        flexDirection: 'row'
    }
}));

const checkEligibility = (wallet: WalletService, proof: ProofObj) => {
    if (wallet.connectedStatus === false ||
        wallet.address === "" ||
        wallet.address === undefined
    ) {
        return false
    }
    if (proof === undefined) {
        return false
    }
    if (proof[Web3.utils.toChecksumAddress(wallet.address)] !== undefined) {
        return true
    } 
    return false
}

const Claim: FC<Props> = (props: Props): JSX.Element => {

    const classes = useStyles();

    const wallet = useContext(WalletContext)

    const gas = useContext(GasContext)

    const [loading, setLoading] = useState(false)

    const [currentState, setCurrentState] = useState<string>('')

    const [maxTotalSupply, setMaxTotalSupply] = useState<number>(-1)
    const [claimCount, setClaimCount] = useState<number>(-1)
    useEffect(() => {
        if (!props.disabled) {
            const getSupplyData = async () => {
                const contract = getClaimableContract(props.contractAddress)
                const supplyData = await contract.getSupplyDataMultiCall()
                setMaxTotalSupply(supplyData.maxTotalSupply)
                setClaimCount(supplyData.claimCount)
            }
            getSupplyData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.disabled])

    const [proofs, setProofs] = useState<ProofDict>({})
    useEffect(() => {
        const catagoryProofs: ProofDict = {}
        props.catagories.map(
            (c) => axios.get(c.proofUrl).then(result => {
                catagoryProofs[c.merkleIdx] = result.data.proofs
            })
        )
        setProofs(catagoryProofs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [hashProof, setHashProof] = useState<Array<HashProofObj>>([])
    useEffect(() => {
        axios.get(props.hashProofUrl).then(result => {
            setHashProof(result.data.proofs)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [eligible, setEligible] = useState(false)
    useEffect(() => {
        props.catagories.forEach(airdrop => {
            if (wallet.connectedStatus === false ||
                wallet.address === "" ||
                wallet.address === undefined
            ) {
                setEligible(false)
            }
            if (checkEligibility(wallet, proofs[airdrop.merkleIdx])) {
                setEligible(true)
            }
        })
    })

    const [uninitialisedTokenIDs, setUninitialisedTokenIDs] = useState<Array<number>>([])

    const getNfts = async () => {
        const contract = getClaimableContract(props.contractAddress)
        const tokenIDs = await contract.getUnitialisedTokenIDsMutliCall(wallet) as []
        setUninitialisedTokenIDs(tokenIDs)
    }
    
    useEffect(() => {
        if (wallet.connectedStatus === false ||
            wallet.address === "" ||
            wallet.address === undefined
        ) {
            setUninitialisedTokenIDs([])
            return
        }

        if (!props.disabled) {
            getNfts()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, wallet.connectedStatus, wallet.address, props.disabled])

    const handleClaimed = () => {
        if (!props.disabled) {
            getNfts()
        }
    }

    const handleInitialisation = () => {
        if (hashProof === undefined) {
            console.error('no hash proof')
            return
        }

        if (uninitialisedTokenIDs.length < 1) {
            return
        }

        if (!props.disabled) {
            const indexes: Array<number> = []
            const hashes: Array<string> = []
            const proofs: Array<Array<string>> = []

            for (let i = 0; i < uninitialisedTokenIDs.length; i++) {
                const hash: HashProofObj = hashProof[uninitialisedTokenIDs[i]]
                if (hash === undefined) {
                    console.error("hash undefined")
                    continue
                }
                indexes.push(hash.index)
                hashes.push(hash.hash)
                proofs.push(hash.proof)
            }

            const doSubmitHashes = async () => {
                setLoading(true)
                const contract = getClaimableContract(props.contractAddress)
                const res = await contract.submitHashes(gas.price, wallet, indexes, hashes, proofs)
                console.log(res)
            }
            doSubmitHashes().then(() => {
                setLoading(false)
                setUninitialisedTokenIDs([])
            }).catch(() => {
                setLoading(false)
            })
        }
    }

    const initialisationActionText = props.initActionText(uninitialisedTokenIDs.length, loading)

    const noStateBtnText = props.noStateBtnText(
        props.disabled,
        wallet.connectedStatus,
        eligible,
        claimCount === maxTotalSupply
    )

    const activeCatagoryBtn: CSSProperties = {
        boxShadow: '0px 0px 0 4px #DCDCDC',
    }

    const submitHashesBtn = <Button
        className={classes.button}
        disabled={uninitialisedTokenIDs.length < 1 || loading}
        variant="contained"
        onClick={handleInitialisation}>
        <Typography className={classes.btnText}>{initialisationActionText}</Typography>
    </Button>

    return (<Container className={classes.content} maxWidth="md">
        <Container className={classes.imageContainer}>
            <img className={classes.image} src={props.image} alt={props.name} />
        </Container>
        <Container className={classes.text}>
            <Container className={classes.info}>
                <Typography variant="h5" className={classes.title}>{props.name}</Typography>
                <Typography className={classes.description}>{props.description}</Typography>
                {!props.disabled && <Typography className={classes.numberClaimed}>{claimCount}/{maxTotalSupply} claimed</Typography>}
            </Container>
            <Container className={classes.airdrop}>
                {!props.disabled && <><Typography className={classes.catagoryTitle}>{props.catagoryText}</Typography>
                <Container className={classes.airdropCatagories}>
                    {props.catagories.map((airdrop: AirdropCatagory, i: number) => (
                        <Button
                            key={i}
                            style={(airdrop.name === currentState ? activeCatagoryBtn : {})}
                            disabled={!checkEligibility(wallet, proofs[airdrop.merkleIdx])}
                            className={classes.catagoryBtn}
                            onClick={() => setCurrentState(airdrop.name)}
                            endIcon={(eligible ? <CheckCircleIcon fontSize="small" /> : <></>)}
                        >
                            {airdrop.name}
                        </Button>
                    ))}
                </Container></>}
                {!props.disabled && props.catagories.map((airdrop: AirdropCatagory, i: number) =>
                    (airdrop.name === currentState ?
                        <ClaimOption
                            key={i}
                            disabled={props.disabled}
                            airdrop={airdrop}
                            claimCount={claimCount}
                            eligible={checkEligibility(wallet, proofs[airdrop.merkleIdx])}
                            proof={proofs[airdrop.merkleIdx]}
                            handleClaimed={handleClaimed}
                            submitHashesBtn={submitHashesBtn}
                            claimText={props.claimText}
                            contractAddress={props.contractAddress}
                        /> : <></>
                    )
                )}
                {(currentState === '' ? <Container className={classes.linkcontainer}>
                    <Typography className={classes.claimOptionInfo} style={{ height: '21px' }}></Typography>
                    <Button
                        style={{ marginBottom: '12px' }}
                        className={classes.button}
                        disabled={true}
                        variant="contained"
                        onClick={() => {}}>
                        <Typography className={classes.btnText}>{noStateBtnText}</Typography>
                    </Button>
                    {(!props.disabled && wallet.connectedStatus) && submitHashesBtn}
                </Container> : <></>)}
            </Container>
        </Container>
    </Container>)
}

export default Claim 