import { FC, useState } from 'react'
import { Container, Link, makeStyles, Theme } from '@material-ui/core'

import Curve from '../airdrops/Curve'
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Sushi from '../airdrops/Sushi';

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        marginTop: theme.spacing(12),
        padding: theme.spacing(1),
    },
    menu: {
        marginTop: theme.spacing(10),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(18),
            flexDirection: 'column'
        },
    },
    link: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        fontFamily: 'Karla',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#000000',
        textTransform: 'none',
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
        padding: '12px',
        borderBottom: '2px solid #ffffff'
    },
    disabledLink: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        fontFamily: 'Karla',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        color: '#000000',
        textTransform: 'none',
        cursor: 'default',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
        padding: '12px',
        opacity: '0.2',
        borderBottom: '2px solid rgba(0,0,0,0)'
    }
}));

const Airdrop: FC = (): JSX.Element => {

    const classes = useStyles()

    const [selectedAirdrop, setSelectedAirdrop] = useState(1)

    const airdrops = [
        {name: 'Airdrop 01: Curve (Finished)', disabled: false, component: <Curve />},
        {name: 'Airdrop 02: Sushi', disabled: false, component: <Sushi />},
        {name: 'Airdrop 03: Coming Soon', disabled: true, component: null}
    ]

    const active: CSSProperties = {
        borderBottom: '2px solid #000000'
    }

    return (<>
        <Container className={classes.menu}>
            {airdrops.map((a, k) => {
                return !a.disabled ? <Link key={k} style={k === selectedAirdrop ? active : {}} className={classes.link} onClick={() => setSelectedAirdrop(k)}>{a.name}</Link> :
                <Link key={k} className={classes.disabledLink}>{a.name}</Link>
            })}
        </Container>
        {airdrops[selectedAirdrop].component}
    </>)
}

export default Airdrop