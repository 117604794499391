import { FC } from 'react'
import { Container, makeStyles, Typography, Link } from '@material-ui/core'

import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        position: 'relative'
    },
    logo: {
        height: '132px',
        marginBottom: theme.spacing(1),
    },
    description: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        '& > p': {
            fontFamily: 'Karla',
            fontWeight: 'bold'
        }
    },
    socialIcon: {
        color: '#000000',
        fontSize: '28px',
        paddingLeft: '5px',
        paddingRight: '5px'
    },
}))

const Splash: FC = (): JSX.Element => {

    const classes = useStyles()

    return (<Container className={classes.root}>
        <Container className={classes.description}>
            <Typography>
            International artist built to make art
            </Typography>
            <Typography>
            from the macabre yet cute designs of life.
            </Typography>
        </Container>
        <Container>
            <Link href="https://twitter.com/anonevoh"><TwitterIcon className={classes.socialIcon} /></Link>
            <Link href="https://www.instagram.com/evoh.ink/"><InstagramIcon className={classes.socialIcon} /></Link>
        </Container>
    </Container>)
}

export default Splash