import { FC } from 'react'
import { makeStyles, Typography, Button } from '@material-ui/core'

import {ReactComponent as EvohLogo } from '../evoh-logo.svg'

import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto'
    },
    logo: {
        fill: '#000000',
        height: '64px',
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(6),
    },
    text: {
        textTransform: 'none',
        fontFamily: 'Karla',
        fontWeight: 'bold',
        color: '#000000',
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
        '&:active': {
            textDecoration: 'none'
        },
        '&:link': {
            textDecoration: 'none'
        },
        '&:visited': {
            textDecoration: 'none'
        }
    }
}))

type Props = {
    padLeft: number
}

const NavLogo: FC<Props> = (props: Props): JSX.Element => {

    const classes = useStyles()

    return (<div className={classes.root} style={{paddingLeft: props.padLeft}}>
        <Link to="/" className={classes.link}>
            <Button><Typography className={classes.text}>Capsule</Typography></Button>
        </Link>
        <EvohLogo className={classes.logo} />
        <Link to="/airdrops" className={classes.link}>
            <Button><Typography className={classes.text}>Airdrops</Typography></Button>
        </Link>
    </div>)
}

export default NavLogo