import React, { FC, useEffect, useState } from 'react'
import { Container, makeStyles, Typography, Button } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import axios from 'axios';
import Web3 from 'web3'
import { ProofObj } from '../airdrops/Airdrop';

const useStyles = makeStyles((theme) => ({
    content: {
    },
    input: {
        width: '100%',
        marginBottom: theme.spacing(1)
    },
    inputLabel: {
        fontFamily: 'DM Sans',
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(4),
        fontFamily: 'Karla',
        fontSize: '20px'
    },
    status: {
        fontFamily: 'DM Sans',
        fontSize: '15px'
    }
}));

type Props = {
    airdropName: string
    proofs: Array<string>
}

const EligibilityChecker: FC<Props> = (props): JSX.Element => {

    const classes = useStyles()

    const [proofs, setProofs] = useState<Array<ProofObj>>([])
    useEffect(() => {
        let allproofs: Array<ProofObj> = []
        props.proofs.forEach(url => {
            axios.get(url)
                .then((result) => {
                    const p = result.data.proofs
                    if (p === undefined) {
                        return
                    }
                    allproofs.push(p)
                })
        }) 
        setProofs(allproofs)
    }, [props.proofs])

    const [ethAddress, setEthAddress] = useState('');

    const [eligibile, setEligibility] = useState<true | false | null>(null)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEligibility(null)
        setEthAddress(event.target.value);
    }

    const checkEthAddress = (event: React.FormEvent) => {
        event.preventDefault()
        if (!Web3.utils.isAddress(ethAddress)) {
            return
        }

        let userProof = undefined
        for (let i = 0; i < proofs.length; i++) {
            if (userProof === undefined) {
                userProof = proofs[i][Web3.utils.toChecksumAddress(ethAddress)]
            }
        }

        if (userProof !== undefined) {
            setEligibility(true)
            return
        } else {
            setEligibility(false)
            return
        }
    }

    let eligibilityText = ""
    if (eligibile) {
        eligibilityText = "This wallet is eligible"
    } else if (eligibile === false) {
        eligibilityText = "This wallet is not eligible"
    } else {
        eligibilityText = ""
    }

    return (<Container maxWidth="xs" className={classes.content}>
        <Typography className={classes.title}> 
            Check {props.airdropName} eligibility
        </Typography>
        <form onSubmit={checkEthAddress}>
            <FormControl variant="outlined" className={classes.input}>
                <InputLabel className={classes.inputLabel} htmlFor="component-outlined">Enter an Ethereum address</InputLabel>
                <OutlinedInput
                    id="component-outlined"
                    value={ethAddress}
                    onChange={handleChange}
                    label="Enter an Ethereum address"
                />
            </FormControl>
            <Button style={{display: 'none'}} type="submit"></Button>
        </form>
        <Typography className={classes.status}> 
            {eligibilityText}
        </Typography>
    </Container>)
}

export default EligibilityChecker