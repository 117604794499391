import { FC } from 'react'
import { makeStyles } from '@material-ui/core'

import SocialLinks from '../social-links/SocialLinks';

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(8),
        justifyContent: 'center',
        verticalAlign: 'middle'
    },
}));

const SocialFooter: FC = (): JSX.Element => {

    const classes = useStyles()

    return (<div className={classes.footer}>
        <SocialLinks color="#000000" />
    </div>)
}

export default SocialFooter