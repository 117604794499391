import { makeStyles, Theme, Container, Typography } from '@material-ui/core';
import { FC } from 'react'

import {ReactComponent as EvohLogo } from '../evoh-logo.svg'

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { Link } from "react-router-dom"

import SocialLinks from '../social-links/SocialLinks';

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        display: 'flex',
        color: '#ffffff',
        backgroundColor: '#000000',
        flexDirection: 'column',
    },
    logo: {
        height: '100px',
        fill: '#ffffff',
        alignSelf: 'flex-start',
        marginBottom: theme.spacing(8),
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    link: {
        display: 'flex',
        color: '#ffffff',
        cursor: 'pointer',
        textDecoration: 'none',
        opacity: 1,
        '&:hover': {
            opacity: '0.7',
            transition: 'opacity 0.3s ease-in',
            textDecoration: 'none'
        },
        alignItems: 'center',
        width: 'fit-content'
    },
    linkText: {
        fontFamily: 'Karla',
        fontWeight: 'bold',
        fontSize: '32px',
        color: '#ffffff',
    },
    linkContainer: {
        padding: 0,
        textAlign: 'left'
    },
    logoContainer: {
        padding: 0,
    },
    description: {
        padding: 0,
        color: '#ffffff',
        textAlign: 'left',
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(1),
        '& > p': {
            fontSize: '40px',
            fontFamily: 'Karla',
            fontWeight: 'bold'
        } 
    },
    socialLinks: {
        padding: 0,
        marginTop: theme.spacing(16)
    },
    
}));

type Props = {
    action: () => void
}

const FullPageMenu: FC<Props> = (props: Props): JSX.Element => {

    const classes = useStyles()

    return (<div className={classes.content}>
        <Container className={classes.logoContainer}>
            <EvohLogo className={classes.logo} />
        </Container>
        <Container className={classes.linkContainer}>
            <Link to="/" onClick={props.action} className={classes.link}>
                <Typography className={classes.linkText}>Capsule Collection</Typography>
                <ArrowRightAltIcon fontSize="large" />
            </Link>
            <Link to="/airdrops" onClick={props.action} className={classes.link}>
                <Typography className={classes.linkText}>Airdrops</Typography>
                <ArrowRightAltIcon fontSize="large" />
            </Link>
        </Container>
        <Container className={classes.description}>
            <Typography>
            International artist built to make art
            </Typography>
            <Typography>
            from the macabre yet cute designs of life.
            </Typography>
        </Container>
        <Container className={classes.socialLinks}>
            <SocialLinks color="#ffffff" />
        </Container>
    </div>)
}

export default FullPageMenu