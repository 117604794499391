import './App.css'
import NavBar from './nav/NavBar'
import Home from './pages/Home'
import Airdrop from './pages/Airdrop'

import {
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

function App() {
  const { pathname } = useLocation()

  const style = (pathname === '/') ? `url('./background-logo.svg')` : 'none'

  return (<div className="App" style={{backgroundImage: style}}>
    <NavBar />
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/airdrops">
        <Airdrop />
      </Route>
    </Switch>
  </div>)
}

export default App