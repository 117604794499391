import { Button, makeStyles, Typography, Container, Theme, createStyles } from '@material-ui/core'
import { FC, useContext } from 'react'
import { WalletContext } from './WalletProvider'

import {ReactComponent as OnIcon } from './on.svg'
import { CSSProperties } from '@material-ui/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 0
        },
        address: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        button: {
            textTransform: 'none',
            boxShadow: 'none',
            border: '1px solid',
            borderRadius: '0',
            '&:hover': {
                transition: 'opacity 0.3s ease-in-out',
                opacity: '0.7',
                boxShadow: 'none',
            }
        },
        btnText: {
            fontFamily: 'Karla',
            fontWeight: 'bold',
            fontSize: '14px'
        }
    })
)

type Props = {
    buttonStyle: CSSProperties
}

const WalletView: FC<Props> = (props: Props): JSX.Element => {

    const classes = useStyles();

    const wallet = useContext(WalletContext)

    const handleClick = () => {
        if (wallet.connectedStatus) {
            wallet.disconnect()
        } else {
            wallet.connect()
        }
    }

    let walletAddressDisplay = ""
    if (wallet.address !== undefined) {
        walletAddressDisplay = `${wallet.address.slice(0, 4)}...${wallet.address.slice(-4, wallet.address.length)}`
    }

    return (<Container className={classes.content}>
        {wallet.connectedStatus ? <><OnIcon />
        <Typography className={classes.address} noWrap>{walletAddressDisplay}</Typography></> : <></>}
        <Button
            className={classes.button}
            style={props.buttonStyle}
            variant="contained"
            onClick={handleClick}>
            <Typography className={classes.btnText}>{wallet.connectedStatus ? "Disconnect" : "Connect Wallet"}</Typography>
        </Button>
    </Container>)
}

export default WalletView